import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import { plugin, defaultConfig } from '@formkit/vue';
import '@formkit/themes/genesis';
import { pt } from '@formkit/i18n';
import Notifications from '@kyvg/vue3-notification';
import easySpinner from 'vue-easy-spinner';
import print from 'vue3-print-nb';

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(Notifications);
appInstance.use(print);
appInstance.use(VueAwesomePaginate);
appInstance.use(plugin, defaultConfig({
  locales: { pt },
  locale: 'pt'
}));
appInstance.use(easySpinner, {
  prefix: 'easy',
  })
appInstance.mount("#app");
