// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBhE5ZCGNJH2qQmS838AIhAfFkIz0OaMYI",
  authDomain: "auth-uniboi-enc.firebaseapp.com",
  projectId: "auth-uniboi-enc",
  storageBucket: "auth-uniboi-enc.appspot.com",
  messagingSenderId: "340816898927",
  appId: "1:340816898927:web:ac67469ad162235328569a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth }
