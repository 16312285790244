import { createRouter, createWebHistory } from "vue-router";
import { auth } from '../firebase';
import Dashboard from "../views/Dashboard.vue";
import Clientes from "../views/Clientes.vue";
import Pedidos from "../views/Pedidos.vue";
import Produtos from "../views/Produtos.vue";
import Retiradas from "../views/Retiradas.vue";
import Encomenda from "../views/Encomenda-new.vue";
import RelRetirados from "../views/RelRetirados.vue";
import RelProdutosPorData from "../views/RelProdutosPorData.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard-default",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pedidos",
    name: "Pedidos",
    component: Pedidos,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/retiradas",
    name: "Retiradas",
    component: Retiradas,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/relretirados",
    name: "Pedidos retirados",
    component: RelRetirados,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/relprodutospordata",
    name: "Produtos por data",
    component: RelProdutosPorData,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/encomendanew",
    name: "encomendanew",
    component: Encomenda,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/produtos",
    name: "produtos",
    component: Produtos,
    meta: {
      requiresAuth: true
    }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.path === '/signin' && auth.currentUser) {
    next('/')
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser) {
    next('/signin')
    return;
  }

  next();
})

export default router;
