import axios from 'axios';
// import setup from '../data/setup';

  async function cancelThisPedido(id, motivo){
    console.log('Motivo Pedido API', motivo)
    await axios.patch(process.env.VUE_APP_API_URL + '/pedidos/' + id, {
      status: "CANCELADO",
      motivo: motivo
    })
    .then(res => {
      console.log(res);
      return res.data;
      })
    };
    // delPedido(id)

    async function getPedidos(pg) {
      const pedidos = await axios.get(process.env.VUE_APP_API_URL + '/pedidos',
      {
        params:
        {'filter[include][]': 'cliente',
         'filter[order]': 'pednum DESC',
         'filter[where][lojaId]' : process.env.VUE_APP_LOJAID,
         'filter[limit]': 100,
         'filter[skip]': 100 * pg
        }
      }); // loja ze bastos
      // console.log("cliente API", pedidos.data);
      return pedidos.data;
    };

    async function getAllPedidosNoPagination(){
      const pedidos = await axios.get(process.env.VUE_APP_API_URL + '/pedidos',
      {
        params: {
         'filter[include][]': 'cliente',
         'filter[order]': 'pednum DESC',
         'filter[where][lojaId]' : process.env.VUE_APP_LOJAID,
        }
      })
        return pedidos.data;
    }

    async function getPedidosRetirados(pg) {
      const pedidos = await axios.get(process.env.VUE_APP_API_URL + '/pedidos',
      {
        params:{
          filter:
          {
            include: ['cliente'],
            order: 'pednum DESC',
            where:{
              lojaId: process.env.VUE_APP_LOJAID,
              status: 'RETIRADO'
            },
            limit: 100,
            skip: 100 * pg
          }
        }
      }); // loja ze bastos
      // console.log("cliente API", pedidos.data);
      return pedidos.data;
    };


    async function countPedidos(){
      var cp = await axios.get(process.env.VUE_APP_API_URL + '/pedidos/count', { params: {
        where:{
          lojaId: process.env.VUE_APP_LOJAID
        }
      }
    })
    //  .then((res)=> {
        // console.log(res.data.count);
        return cp.data.count;
      //  })
      };


    async function retiraThisPedido(id){
      // console.log('API ID Pedido', id)
      await axios.patch(process.env.VUE_APP_API_URL + '/pedidos/' + id, {
        status: "RETIRADO"
      })
      .then(res => {
        // console.log(res);
        return res.data;
        })
      };  //retira pedido

  async function searchCliFone(id){
      // console.log('search on service', id)
      var clie = await axios.get(process.env.VUE_APP_API_URL + '/clientes', {
        params: {
          'filter[where][fone]': id
        }
      }).then((res)=>{
        console.log('return from api', res.data)
        return res.data
      })
      return clie;
    }

    async function searchRfidCard(card){
      // console.log('search on service', id)
      var clie = await axios.get(process.env.VUE_APP_API_URL + '/pedidos', {
        params: {
          'filter[where][rfidcard]': card
        }
      }).then((res)=>{
        console.log('return from api', res.data)
        return res.data
      })
      return clie;
    }

    async function searchPedidoCartaoData(id){
      var pedId = await axios.get(process.env.VUE_APP_API_URL + '/pedidos' , {
        params: {
          filter: {
            include: ['cliente'],
            where: {
              rfidcard: id,
              lojaId: process.env.VUE_APP_LOJAID
            }
          },
        }
      });
      console.log(pedId.data);
      return pedId.data;
    }
  
    async function searchPedidoIdData(id){
    var pedId = await axios.get(process.env.VUE_APP_API_URL + '/pedidos' , {
      params: {
        filter: {
          include: ['cliente'],
          where: {
            pednum: id,
            lojaId: process.env.VUE_APP_LOJAID
          }
        },
      }
    });
    console.log(pedId.data);
    return pedId.data;
  }

  async function searchPedCliNomeData(nomesearch){
    // const pattern = new RegExp(
    //     '.*' + nomesearch + '.*',
    //     'i',
    //   );
    var cliente = await axios.get(process.env.VUE_APP_API_URL + '/clientes' , {
      params:{
        filter:{
          fields: ["_id"],
          where: {
            nome: {
              like: nomesearch,
              options: "i"
            },
            lojaId: process.env.VUE_APP_LOJAID
          }
        }
      }
    });
    console.log(cliente);
    if (cliente.data.length >= 1){
      console.log('cliente ID', cliente.data)
      var arrCli = cliente.data.map((cli)=>{
        return cli._id
      });
      console.log("ac", arrCli);
      var pedCliente = await axios.get(process.env.VUE_APP_API_URL + '/pedidos' , {
        params: {
          filter: {
            include: ['cliente'],
            where: {
              cliente_id: { inq: arrCli },
              lojaId: process.env.VUE_APP_LOJAID
            }
          },
        }
      });
      console.log("pcd", pedCliente.data);
      return pedCliente.data;

    } else {
      return null;
    }
  }



export {
   cancelThisPedido,
   searchCliFone,
   retiraThisPedido,
   countPedidos,
   searchPedidoIdData,
   searchPedCliNomeData,
   getPedidos,
   getPedidosRetirados,
   getAllPedidosNoPagination,
   searchRfidCard,
   searchPedidoCartaoData
}
