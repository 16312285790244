<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Produtos</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Unidade</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Status</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Preço</th>
              <th v-if="userPermited" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ação</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="prod in produtos" :key="prod._id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ prod.nomeproduto }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{prod.unidade}}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">ATIVO</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{Number(prod.precoproduto).toFixed(2)}}</span>
                </td>
                <td v-if="userPermited" class="align-middle">
                  <a
                    href="javascript:;"
                    class="ni ni-fat-remove text-danger text-center"
                    data-toggle="tooltip"
                    data-original-title="Excluir produto"
                    @click="excluirProduto(prod._id)"
                  ></a>
                  <a
                    href="javascript:;"
                    class="ni ni-ruler-pencil text-primary text-center"
                    data-toggle="tooltip"
                    data-original-title="Editar produto"
                    data-bs-toggle="modal" 
                    data-bs-target="#editarProduto"
                    @click="buscaProduto(prod._id, prod.nomeproduto,prod.unidade, prod.precoproduto)"
                  ></a>
                 
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



      <!-- Modal -->
        <div class="modal fade" id="editarProduto" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Editar Produto</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col pb-3 ">
                    <FormKit
                    type="text"
                    label="Nome"
                    help="Nome do produto"
                    v-model="nomeproduto"
                    validation="required"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col pb-3 ">
                    <FormKit
                    type="select"
                    label="Unidade"
                    placeholder="Selecione a unidade"
                    :options="[
                      {label: 'UN', value: 'UN'},
                      {label: 'KG', value: 'KG'},
                      {label: 'CAIXA', value: 'CAIXA'}]"
                    v-model="unidadeproduto"
                    validation="required"
                    />
                  </div>
                  <div class="col pb-3 ">
                    <FormKit
                    type="text"
                    label="Preço"
                    v-model="precoproduto"
                    validation="required"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="editarProduto(idproduto)">Salvar</button>
              </div>
            </div>
          </div>
          </div>




</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import {mask} from 'vue-the-mask';
// import cliente from '../../store';


export default {
  name: "produtos-table",
  data(){
    return{
      nomeproduto: '',
      unidadeproduto: '',
      precoproduto: '',
      idproduto: '',
      userPermited: false
    }
  },

//  computed: mapState({
//    clientes: state => state.cliente.getAllClientes
//  }),
  computed: {
    ...mapState({
      produtos: state => state.produtos.all
    }),
  },

  methods:{
    getDataProdutos(){
      this.$store.dispatch('produtos/getAllProdutos')
      // console.log(this.clientes)
    },
    formatDate(value){
      return moment(String(value)).format('DD/MM/YYYY')
    },

    excluirProduto(id){
      console.log(id)
      this.$store.dispatch('produtos/removeSetProduto', id)
    },
    buscaProduto(id, nome, unidade, preco){
      // console.log(id);
      // this.$store.dispatch('produtos/editaSetProduto', id)
      this.$store.dispatch('produtos/getProduto', id);
      this.nomeproduto = nome;
      this.unidadeproduto = unidade;
      this.precoproduto = preco;
      this.idproduto = id;
    },
    editarProduto(id){
      var dataAltera = {
        nome: this.nomeproduto,
        unidade: this.unidadeproduto,
        preco: Number(this.precoproduto),
        id: id
      };
      // console.log(id);
      this.$store.dispatch('produtos/editaSetProduto', dataAltera )
    }
  },
  mounted() {
    this.getDataProdutos();
    console.log(this.produtos);
    // console.log(state.produtos.all);

    if(this.$store.state.user?.email === "gerente@uniboi.co"){
      this.userPermited = true
    }

  },
  directives: {mask},
};
</script>
