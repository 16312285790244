import { createStore } from "vuex";
import clientes from './modules/clientes';
import produtos from './modules/produtos';
import pedidos from './modules/pedidos';
import { auth } from '../firebase';
import router from "../router";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut
 } from 'firebase/auth';


export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    user: '',

  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    set_user(state, user){
      state.user = user;
    },
    clear_user(state){
      state.user = null;
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    // async login({commit}, details){
    //
    // },
    async register({commit}, details){
      const { email, password } = details;
      console.log(details);

      try{
        await createUserWithEmailAndPassword(auth, email, password)
      } catch(error) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            alert("Usuário com email já cadstrado")
            break;
          case 'auth/operation-not-allowed':
            alert("Operação não permitida")
            break;
          case 'auth/weak-password':
            alert('Senha muito fraca')
            break

          default:
            alert("Algo de errado ocorreu!")

        }
        return
      }
      commit('set_user', auth.current_user);
      // eslint-disable-next-line no-undef
      router.push('/');
    },
    async login({commit}, details){
      const { email, password } = details;
      console.log(details);

      try {
        await signInWithEmailAndPassword(auth, email, password)
      } catch (error) {
        switch(error.code) {
          case 'auth/user-not-found':
            alert("Usuário não encontrado")
            break
          case 'auth/wrong-password':
            alert("Senha errada")
            break
          default:
            alert("Algo de errado ocorreu!")
        }

        return
      }

      commit('set_user', auth.currentUser)

      router.push('/')
    },
    async logout ({ commit }) {
      await signOut(auth)

      commit('clear_user')

      router.push('/signin')
    },

    fetchUser ({ commit }) {
      auth.onAuthStateChanged(async user => {
        if (user === null) {
          commit('CLEAR_USER')
        } else {
          commit('SET_USER', user)

          if (router.isReady() && router.currentRoute.value.path === '/login') {
            router.push('/')
          }
        }
      })
    }

  },
  getters: {},
  modules: {
    clientes,
    produtos,
    pedidos
  }
});
