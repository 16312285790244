<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Clientes</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Endereço</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Status</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >CPF</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="cliente in clientes" :key="cliente._id">
                <td>
                  <div class="d-flex px-3 py-1">
                    <div>
                  <!--    <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user1"
                      /> -->
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ cliente.nome }}</h6>
                      <p class="text-xs text-secondary mb-0"><span v-text="formatPhone(cliente.fone)"></span></p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{cliente.logradouro}}, {{cliente.numero}}</p>
                  <p class="text-xs text-secondary mb-0">{{cliente.complemento}} - {{cliente.bairro}} - {{ cliente.cidade}} - {{ cliente.uf }}- CEP: {{ cliente.cep }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">ATIVO</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{cliente.cpf}}</span>
                </td>
                <td class="align-middle">
                  <a
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Editar cliente"
                  >Editar</a>
                </td>
              </tr>

          </tbody>
        </table>
      </div>
      <div class="col mx-4" v-if="sk > (cn / 20)">
        <argon-alert >
          Final dos clientes
        </argon-alert>
      </div>
      <button class="btn btn-seconday mx-3 my-2" @click="getDataClientes()">Carregar mais clientes</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import {mask} from 'vue-the-mask';
import ArgonAlert from "@/components/ArgonAlert.vue";

// import cliente from '../../store';


export default {
  name: "cliente-table",
  data(){
    return {
      sk: 0,
    //  cn: 0
    }
  },

//  computed: mapState({
//    clientes: state => state.cliente.getAllClientes
//  }),
  computed: {
    ...mapState({
      clientes: state => state.clientes.all,
      cn: state => state.clientes.cn
    }),

  },

  methods:{
    getDataClientes(){
      this.$store.dispatch('clientes/getAllClientes', this.sk);
      this.sk = this.sk + 20;
      console.log('CN', this.cn);
    },
    getCNumb(){
      this.$store.dispatch('clientes/getNumberClientes');
    },
    formatDate(value){
      return moment(String(value)).format('DD/MM/YYYY')
    },
    formatPhone(fone){
      return "(" + String(fone).slice(0,2) + ")" + String(fone).slice(2,7) + "-" + String(fone).slice(7);
    }


  },
  mounted() {
    this.getDataClientes();
    this.getCNumb();

    // this.cs = state.cn
    // console.log(this.clientes);

  },
  directives: {mask},
  components: {
    ArgonAlert,
  },
};
</script>
