import axios from 'axios';
// import setup from '../data/setup';

  async function getProdutos(){
      const produtos =  await axios.get(process.env.VUE_APP_API_URL + '/produtos', { params: {
        filter: {
          where: {
            lojaId: process.env.VUE_APP_LOJAID
            }
          }
        }
      }); // 
      return produtos;
  };

  async function addProduto(produto){
    // console.log('API ID Pedido', id)
    await axios.post(process.env.VUE_APP_API_URL + '/produtos/', produto) 
    .then(res => {
      console.log(res);
      // return res.data;
      })
  };
    // delPedido(id)
  async function removeProduto(produto){
      await axios.delete(process.env.VUE_APP_API_URL + '/produtos/' + produto)
      .then(res =>{
        console.log(res);
      })
  };
  async function getProduto(produto){
    const datapro = await axios.get(process.env.VUE_APP_API_URL + '/produtos/' + produto)
    .then(res =>{
      console.log(res);
      return res;
    })
    return datapro;
  };
  async function editaProduto(data){
    await axios.patch(process.env.VUE_APP_API_URL + '/produtos/' + data.id, {
      precoproduto: data.preco,
      nomeproduto: data.nome,
      unidade: data.unidade
    })
    .then(res =>{
      console.log(res);
    })
  };


export {
   getProdutos,
   addProduto,
   removeProduto,
   editaProduto,
   getProduto
}
