<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
        <encomenda-form />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
        
      </div>
    </div>
  </div>
</template>


<script>
import EncomendaForm from "./components/EncomendaForm.vue";

export default {
  name: "encomenda",
  components: {
    EncomendaForm,
    // ProjectsTable
  },
  data() {
    return {

    };
  },
};
</script>
