<template>
  <div class="card mb-3" v-if="groupped">
    <div class="card-header pb-0">
            <div class="row">
              <div class="col">
                <button type="button" class="btn" :class="{'btn-warning': varStatus == 'ATIVO'}" @click="chageStatus('ATIVO')">ATIVOS</button>
              </div>
              <div class="col">
                  <button type="button" class="btn" :class="{'btn-warning': varStatus == 'RETIRADO'}" @click="chageStatus('RETIRADO')">RETIRADOS</button>
              </div>
              <div class="col">
                  <button type="button" class="btn" :class="{'btn-warning': varStatus == 'CANCELADO'}" @click="chageStatus('CANCELADO')">CANCELADOS</button>
              </div>
            </div>
            <p v-for="(sec, ix) in sections" :key="ix">
                <span class="text-warning"><strong>{{ formatDate(sec.date) }}</strong></span>
                <ul>
                  <li v-for="(ped, ii) in sec.pedidos" :key="ii">
                    {{ ped.pednum }} - {{ ped.cliente.nome }} - Valor: R$ {{ ped.total }}
                    <p v-for="(pro, ip) in  ped.produtos" :key="ip">
                      {{ pro.produto }} - Quant: {{ pro.quant }}
                    </p>
                  </li>
                </ul>
            </p>
    </div>
    <div class="card-footer pb-0">

    </div>
  </div>
  <div class="card mb-3" v-if="groupped">
    <div class="card-header pb-0">
      <div v-if="varStatus == 'ATIVO'">
              <h5>Resumo de totais de produtos em pedidos Ativos</h5>
              <p v-for="(item, ix) in objSomas" :key="ix">
                  <span class="text-warning"><strong>{{ formatDate(item.data) }}</strong></span>
                <span v-for="(it, ii, ikx) in item.produtos" :key="ii">
                  <p>{{ ii }} -

                  Totais: {{ item.totais.soma[ikx] }}</p>
                  <!-- <p v-for="(suu, iix) in it.totais" :key="iix">
                    Soma: {{ suu }}
                  </p> -->
                </span>
              </p>
          </div>
    </div>
    <div class="card-footer pb-0">

    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import moment from 'moment';
import {mask} from 'vue-the-mask';
import _ from 'lodash';
// import axios from 'axios';
import { getAllPedidosNoPagination } from '../../service/ApiPedido';
// import SearchInput from 'vue-search-input';

export default {
  name: "RelRetiradosTable",

//  computed: mapState({
//    clientes: state => state.cliente.getAllClientes
//  }),
  data(){
    return{
      pedido_id: '',
      groupped: {},
      sections: {},
      varStatus: 'ATIVO',
      objSomas: {},
      pedidos: []
    }
  },
  computed: {
    //  ...mapState({
    //    pedidos: state => state.pedidos.all
    //  }),
  },
  watch: {


   },

  methods:{
    // async getDataPedidosRetirados(){
    //   // this.retirados = await this.$store.getters['pedidos/getPedidoRetirados']
    //   console.log('THIS.RETIRADOS', this.retirados)
    // },
    formatDate(value){
      return moment(String(value)).format('DD/MM/YYYY')
    },
    formatPhone(fone){
      return "(" + String(fone).slice(0,2) + ")" + String(fone).slice(2,7) + "-" + String(fone).slice(7);
    },
    // cancelarPedido(id){
    //   this.$store.dispatch('pedidos/cancelaPedido', id)
    //   console.log(id)
    // },
    changeDot(strv){
      return String(Number(strv).toFixed(2)).replace(".",",");
    },
    // atualizaPedidosBusca(pedidos){
    //   this.$store.dispatch('pedidos/updatePedidoAction', pedidos)
    // }
    chageStatus(status){
      this.varStatus = status;
      // console.log(this.pedidos);
        var filtered = this.pedidos.filter((ped)=> ped.status == status)
        this.groupped = _.groupBy(filtered, ped => ped.dataretirada.substring(0, 10));
        // console.log('Grupped', this.groupped);

        this.sections = _.map(this.groupped, (items, date) => ({
          date: date,
          pedidos: items
        }))
      }

  },
  async mounted() {
    // this.$store.dispatch('pedidos/getAllNoPag');
    this.pedidos = await getAllPedidosNoPagination();
    setTimeout(()=>{
      // console.log(this.pedidos);
      var filtered = this.pedidos.filter((ped)=> ped.status == this.varStatus)
      this.groupped = _.groupBy(filtered, ped => ped.dataretirada.substring(0, 10));
      // console.log('Grupped', this.groupped);

      this.sections = _.map(this.groupped, (items, date) => ({
        date: date,
        pedidos: items
      }));

      this.objSomas = _.map(this.groupped , (item, dataagr)=>({
        data: dataagr,
        produtos: _.groupBy(_.flatten(_.flatMap(item, 'produtos')), 'produto') ,
        totais:  {
          soma: _.map(_.groupBy(_.flatten(_.flatMap(item, 'produtos')), 'produto'), (k)=> { return _.sumBy(k, (i)=> Number(i.quant)) })
            }
          }
        )
      );

      // console.log('objSomas', this.objSomas);

      // console.log(this.sections);

    }, 1000)


  //  this.getDataPedidosRetirados();
    // console.log(this.pedidos);
  },
  directives: {mask},
  components: {
//    SearchInput,

  },
};
</script>
