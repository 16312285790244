<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-md-2">
          <h6>Pedidos</h6>
        </div>
        <div class="col-md-4">
          <SearchInput
            v-model="searchPedidosId"
            type="search"
            placeholder="Busca número"
            wrapperClass="search-input-wrapper"
            :class="['search-input-wrapper mt-2 w270']"
            :clearIcon="true"
            :clearOnEsc="true"
            :blurOnEsc="true"
            :selectOnFocus="true"
            :shortcutListenerEnabled="true"
            shortcutKey= "?"
          />
          <easy-spinner
          type="circular"
          size="20px"
          color="grey"
          v-if="searchCliSpin"/>
        </div>

        <div class="col-md-4">
          <SearchInput
            v-model="searchPedidosCliente"
            type="search"
            placeholder="Busca clientes"
            wrapperClass="search-input-wrapper"
            :class="['search-input-wrapper mt-2 w270']"
            :clearIcon="true"
            :clearOnEsc="true"
            :blurOnEsc="true"
            :selectOnFocus="true"
            :shortcutListenerEnabled="true"
            shortcutKey= "/"
          />
          <easy-spinner
          type="circular"
          size="20px"
          color="grey"
          v-if="searchIdSpin"/>
        </div>

      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2"  >
      <div class="table-responsive p-0">
        <table class="table align-items-left mb-0" v-if="pedidos">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Cliente</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Valor</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Produtos</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Status</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Data Pedido</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Data Retirada</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Horário Retirada</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ação</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="pedido in pedidos" :key="pedido._id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <img
                        src="../../assets/img/flag-BR.png"
                        class="avatar avatar-sm me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">Pedido: {{ pedido.pednum }} </h6>
                      <p class="text-xs text-secondary mb-0">#{{ pedido._id }}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ pedido.cliente.nome }}</p>
                  <p class="text-xs text-secondary mb-0">{{ formatPhone(pedido.cliente.fone) }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ changeDot(pedido.total) }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success" data-bs-toggle="modal" v-bind:data-bs-target="'#prodListModal' + pedido._id">{{ pedido.produtos.length }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                <!--  <span :class="pedido.status == 'ATIVO'?'badge badge-sm bg-gradient-success' : 'badge badge-sm bg-gradient-warning' " >{{ pedido.status }}</span> -->
                  <span :class="{'badge badge-sm bg-gradient-success':  pedido.status == 'ATIVO' , 'badge badge-sm bg-gradient-warning': pedido.status == 'RETIRADO' , 'badge badge-sm bg-gradient-danger': pedido.status == 'CANCELADO'}" >{{ pedido.status }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ formatDate(pedido.datapedido) }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ formatDate(pedido.dataretirada) }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ pedido.faixaHorario }}</span>
                </td>
               <td class="align-middle">
              <!--     <a
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Editar pedido"
                  >Editar</a> -->
                 <a
                    data-bs-toggle="modal"
                    v-bind:data-bs-target="'#cancelaPedidoModal' + pedido._id"
                    class="btn text-danger font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Cancelar pedido"
                    type="button"
                    v-if="pedido.status == 'ATIVO'"
                  > Cancelar</a>
                  <!-- <a
                     data-bs-toggle="modal"
                     v-bind:data-bs-target="'#comprovantePedidoModal' + pedido._id"
                     class="btn text-danger font-weight-bold text-xs"
                     style="margin-left: 5px;"
                     data-toggle="tooltip"
                     data-original-title="Reimprimir Comprovante"
                     type="button"
                     v-if="pedido.status == 'ATIVO'"
                   > Comprovante</a> -->
                </td>
                <!-- Modal ver produtos-->
                  <div class="modal fade" v-bind:id="'prodListModal' + pedido._id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">Produtos</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p>Pedido: {{pedido.pednum}}</p>
                          <table class="table table-striped">
                            <thead>
                              <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Produtos</span></th>
                              <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quant</span></th>
                              <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Valor</span></th>
                            </thead>
                            <tbody>
                              <tr v-for="(pro, index) in pedido.produtos" :key="index">
                                <td>{{ pro.produto }}</td>
                                <td>{{ pro.quant }}</td>
                                <td>{{ changeDot(pro.total) }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <p class="float-end">Total do pedido: R$ {{changeDot(pedido.total)}}</p>
                          <p class="float-start">Pagamento: {{ pedido.pagamento }}</p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Modal cancela-->
                    <div class="modal fade" v-bind:id="'cancelaPedidoModal' + pedido._id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1 class="modal-title fs-5" v-bind:id="'cancelaPedidoModalLabel' + pedido._id">Cancela Pedido: {{ pedido.pednum }}</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <p>Confirme o cancelamento do pedido número: {{ pedido.pednum }}</p>
                            <div class="row">
                              <div class="col-6">
                                <FormKit
                                type="password"
                                label="Senha de cancelamento"
                                v-model="senhacancela"
                                />
                              </div>
                              <div class="col-6">
                                <FormKit
                                type="textarea"
                                label="Motivo cancelamento"
                                v-model="motivocancela"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="cancelarPedido(pedido._id, motivocancela)">Cancelar</button>
                          </div>
                        </div>
                      </div>
                    </div> <!-- fim modal cancela -->
                    <!-- Modal comprovante-->
                      <div class="modal fade" v-bind:id="'comprovantePedidoModal' + pedido._id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h1 class="modal-title fs-5" v-bind:id="'comprovantePedidoModalLabel' + pedido._id">Comprovante do Pedido: {{ pedido.pednum }}</h1>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <p>Confirme a reimpressão comprovante de retirada do pedido número: {{ pedido.pednum }}</p>
                              <div class="row">
                                <div class="col-6">
                                  <FormKit
                                  type="password"
                                  label="Senha de reimpressão"
                                  v-model="senhacancela"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                              <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="reimpressao(pedido._id)">Imprimir</button>
                            </div>
                          </div>
                        </div>
                      </div> <!-- fim modal comprovante -->
              </tr>
          </tbody>
        </table>
        <!-- Modal inpressao -->
        <!-- <div class="modal fade" tabindex="-1" aria-labelledby="comprovanteMd" aria-hidden="true" id="modalImpressao">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="comprovanteMd">Impressão do comprovante Retirada</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row ped-impress" id="impress">
                  <img :src="imagem" style="width: 200px;">
                  <h6>UNIBOI Carnes & Grill</h6>
                  <p>Pedido Num: {{ retPedNum }}</p>
                  <p>Nota Fiscal: {{ notafiscal }}</p>
                  <p>Cliente: {{ nome }} </p>
                  <p>Endereço: {{ logradouro }}, {{ numero }} - {{ complemento }}</p>
                  <p>Telefone: {{ fone }}</p>
                  <p>Data: </p>
                  <p>Data da entrega:  {{ dataretirada }}  - Horário: 10:00hs</p>
                  <p>Produtos: </p>
                  <ul>
                    <li v-for="(linha, index) in lps" :key="index">
                        {{ linha.produto }} - Quant: {{ linha.quant }} - R$ {{ linha.total }}
                    </li>
                  </ul>
                  <p>Total: {{ totalgeral }} </p>
                  <p>A presentação desse comprovante garante a retirada do seu pedido, esse comprovante</p>
                  <p>é único e instránsferivel. Deve ser apresentado no dia da retirada.</p>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" v-print="impressObj" @click="sendClearForm()">Imprime comprovante</button>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="paginate-data">
        <vue-awesome-paginate
        :total-items=totalpedidos
        :items-per-page="100"
        :max-pages-shown="5"
        v-model="currentPage"
        :on-click="onClickHandler"
      />
      <p>Total de pedidos: {{totalpedidos}}</p>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import {mask} from 'vue-the-mask';
import SearchInput from 'vue-search-input';
import setup from '../../data/setup.json';
import { notify } from "@kyvg/vue3-notification";
import { Modal } from 'bootstrap';
import QRCode from 'qrcode';


export default {
  name: "pedido-table",

//  computed: mapState({
//    clientes: state => state.cliente.getAllClientes
//  }),
  data(){
    return{
      pedido_id: '',
      searchPedidosCliente: '',
      searchCliSpin: false,
      searchPedidosId: '',
      searchIdSpin: false,
      senhacancela: '',
      motivocancela: '',
      imagem: null,
      impressObj: {
        id: "impress",
        preview: false
      },
      currentPage: 1,
      // totalpedidos: 0
    }
  },
  computed: {
    ...mapState({
      pedidos: state => state.pedidos.all,
      totalpedidos: state => state.pedidos.pedidoscount
    }),

  },
  watch: {
    searchPedidosCliente(nome){
      console.log("altera");
      if (nome.length > 4){
        this.searchIdSpin = true;
        // var selected = this.pedidos.filter((item)=> (item.cliente.nome).toLowerCase() == nome.toLowerCase() );
        //var selected = this.pedidos.filter((item)=> (item.cliente.nome).toLowerCase().includes(nome.toLowerCase()) );
      //  console.log(nome);
        clearTimeout(this.timeCli);
        this.timeCli = setTimeout(()=>{
          this.$store.dispatch('pedidos/searchPedCliNome', nome)

          //if (selected){
              console.log('encontrado pedidos');
            //  setTimeout(()=>{
                this.searchIdSpin = false;
            //   },2000)
        }, 2000);
    } else {
        this.getDataPedidos(0);
      }
    },
    searchPedidosId(id){
      // console.log("altera");
      // setTimeout(()=>{
        if (id.length > 0){
          this.$store.dispatch('pedidos/searchPedId', id)
          this.searchCliSpin = true;
          // console.log("ID",id);
          // console.log('encontrado pedidos');
          setTimeout(()=>{
              this.searchCliSpin = false;
          },1500);
        }  else {
          this.getDataPedidos(0);
        };

   }
  },

  methods:{
    getDataPedidos(pg){
      this.$store.dispatch('pedidos/getAllPedidos', pg)
      // console.log(this.clientes)
    },
    countPedidosDataMethod(){
      this.$store.dispatch('pedidos/countPedidosData')

    },
    formatDate(value){
      return moment(String(value)).format('DD/MM/YYYY')
    },
    formatPhone(fone){
      return "(" + String(fone).slice(0,2) + ")" + String(fone).slice(2,7) + "-" + String(fone).slice(7);
    },
    cancelarPedido(id, motivo){
      console.log(setup.senhaCancelamento);
      if(this.senhacancela == setup.senhaCancelamento){
        this.$store.dispatch('pedidos/cancelaPedido', {id: id, motivo: motivo});
        this.senhacancela = '';
        this.motivocancela = '';
        console.log(motivo)
        notify({
          title: "Pedido cancelado",
          text: "O pedido foi cancelado",
          position: 'top right',
          duration: 10000,
          type: 'success',
          classes: 'mt-5'

        });
      } else {
        // console.log('senha errada');
        notify({
          title: "Senha errada",
          text: "Pedido não cancelado",
          position: 'top right',
          duration: 10000,
          type: 'error',
          classes: 'mt-5'
        });
        this.senhacancela = '';
        this.motivocancela = '';
      }

    },
    reimpressao(id){
      console.log(setup.senhaCancelamento);
      if(this.senhacancela == setup.senhaCancelamento){
        console.log('reimpressao', id);
        //this.$store.dispatch('pedidos/cancelaPedido', {id: id, motivo: motivo});
        this.senhacancela = '';
      
      } else {
        // console.log('senha errada');
        notify({
          title: "Senha errada",
          text: "Pedido não impresso",
          position: 'top right',
          duration: 10000,
          type: 'error',
          classes: 'mt-5'
        });
        this.senhacancela = '';
      }
    },

    imprimirPedidoQrCode(pedId){
      QRCode.toDataURL(pedId, {width: '200'})
        .then(url => {
        //  console.log(url);
          this.imagem = url;

        })
        .catch(err => {
          console.error(err)
        })

      var myModal = new Modal(this.modalImpressao);
      myModal.show();
      // console.log("modal open", myModal.sho );
    },


    changeDot(strv){
      return String(Number(strv).toFixed(2)).replace(".",",");
    },
    atualizaPedidosBusca(pedidos){
      this.$store.dispatch('pedidos/updatePedidoAction', pedidos)
    },

    onClickHandler(page){
      console.log("page", page);
      this.getDataPedidos(page - 1);
    }

  },
  mounted() {
    this.countPedidosDataMethod();
    this.getDataPedidos(0);
    // console.log('Pedidos', this.pedidos);
  },
  directives: {mask},
  components: {
    SearchInput,

  },
};
</script>

<style>
.paginate-data{
  margin-left: 10px;
}

.pagination-container {
    display: flex;
    column-gap: 10px;
  }
  .paginate-buttons {
    height: 40px;
    width: 40px;
    border-radius: 20px!important;
    cursor: pointer;
    background-color: rgb(242, 242, 242);
    border: 1px solid rgb(217, 217, 217);
    color: black;
  }
  .paginate-buttons:hover {
    background-color: #d8d8d8;
  }
  .active-page {
    background-color: #3498db;
    border: 1px solid #3498db;
    color: white;
  }
  .active-page:hover {
    background-color: #2988c8;
  }

</style>
