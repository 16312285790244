<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
          <router-link :to="{ path: '/encomendanew' }" tag="button" class="btn base-button btn-icon btn-fab btn-primary" style="width:180px;">Novo Pedido</router-link>
        <pedido-table />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">
      </div>
    </div>
  </div>
</template>

<script>
import PedidoTable from "./components/PedidoTable.vue";

export default {
  name: "pedidos",
  components: {
    PedidoTable,

  },
  // data() {
  //   return {
  //     stats: {
  //       titleColor: "opacity-7 text-white",
  //       descColor: "text-white",
  //       trip: {
  //         title: "Today's Trip",
  //         desc: "145 KM",
  //         classIcon: "text-dark ni ni-money-coins",
  //       },
  //       health: {
  //         title: "Battery Health",
  //         desc: "99 %",
  //         classIcon: "text-dark ni ni-controller ",
  //       },
  //       speed: {
  //         title: "Average Speed",
  //         desc: "56 Km/h",
  //         classIcon: "text-dark ni ni-delivery-fast",
  //       },
  //       volume: {
  //         title: "Music Volume",
  //         desc: "15/100",
  //         classIcon: "text-dark ni ni-note-03",
  //       },
  //     },
  //   };
  // },
  methods: {
    openForm() {
      console.log('open');

    }
  }
};
</script>
