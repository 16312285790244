<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Retirada de Pedidos</h6>
    </div>
    <div class="card-body">
      <div class="card-title">
        Passe o cartão na leitora para localizar o pedido e processar a retirada
        <div class="row">
          <div class="col pb-3 ">
            <FormKit
            type="number"
            label="Cartão do Pedido"
            v-model="numPed"
            />
            <button class="btn btn-primary" @click="procura(numPed)">Procura Pedido</button>
          </div>
        </div>
        <div class="" v-if="pedRetornado">
          <div class="row">
            <div class="col pb-3">
              <p>Pedido Número: {{ pedRetornado.pednum }}</p>
              <p>Pedido: {{ pedRetornado.cliente.nome }}</p>
              <p>Data Retirada: {{ formatDate(pedRetornado.dataretirada) }}</p>
              <p>Produtos:</p>
              <ul>
                <li v-for="(pro, index) in pedRetornado.produtos" :key="index">{{ pro.produto }} - Quantidade: {{ pro.quant }} - Valor: R$ {{ Number(pro.total).toFixed(2) }}</li>
              </ul>
              <p>Forma Pagamento: {{ pedRetornado.pagamento }}</p>
              <p>Valor total do pedido: R$ {{ (pedRetornado.total).toFixed(2) }}</p>
            </div>
          </div>
          <div class="row" v-if="pedRetornado.status === 'ATIVO'">
            <div class="col pb-3">
                <button class="btn btn-warning" @click="confirmaRetirada(pedRetornado._id)">Confirma Retirada</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent , ref  } from "vue";
import { useStore  } from 'vuex';
import moment from 'moment';
import { useNotification } from "@kyvg/vue3-notification";
// eslint-disable-next-line no-unused-vars
import { searchPedidoIdData, searchPedidoCartaoData } from "../../service/ApiPedido"
//import { useNotification } from "@kyvg/vue3-notification";

export default defineComponent({
   name: 'RetiradaForm',
   setup(){
     const numPed = ref('');
     const store = useStore();
     var pedRetornado = ref();
     const { notify }  = useNotification();

     // onMounted(()=>{
     //   console.log('mounted');
     //   // store.dispatch('pedidos/getAllPedidos', 0)
     // });

     async function procura(id){
      var pedRet = await searchPedidoCartaoData(id);
      // var pedRet = await searchPedidoIdData(id);
      pedRetornado.value = pedRet[0];
      console.log('pedRetornado.value', pedRetornado.value);
        if (pedRetornado.value && pedRetornado.value?.status == 'RETIRADO'){
          pedRetornado.value = false;
          // console.log('ja retirado', pedRetornado.value);
          notify({
            title: "Atenção. Pedido já retirado!",
            text: "Esse pedido não pode ser retirado.",
            position: 'top center',
            duration: 5000,
            type: 'error',
            classes: 'mt-5'
          });
        } else if(pedRetornado.value?.status == 'CANCELADO'){
          notify({
            title: "Atenção. Pedido Cancelado!",
            text: "Esse pedido não pode ser retirado.",
            position: 'top center',
            duration: 5000,
            type: 'error',
            classes: 'mt-5'
          });
        } else if(pedRetornado.value === undefined){
          notify({
            title: "Atenção. Pedido não existe!",
            text: "Esse pedido não existe, verifique.",
            position: 'top center',
            duration: 5000,
            type: 'error',
            classes: 'mt-5'
          });
          console.log('nao localizado')
        }
     };

     async function confirmaRetirada(id){
       console.log(id);
       setTimeout(()=>{
         store.dispatch('pedidos/retiraPedido', id);
         pedRetornado.value = false;

       }, 1000 );
     };

     function formatDate(value){
       return moment(String(value)).format('DD/MM/YYYY')
     };

     return {
       numPed,
       procura,
       pedRetornado,
       formatDate,
       confirmaRetirada
     }
   }
});
</script>
