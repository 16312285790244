// import axios from 'axios';
import {cancelThisPedido, retiraThisPedido, countPedidos, searchPedidoIdData, searchPedCliNomeData , getPedidos, getAllPedidosNoPagination } from '../../service/ApiPedido'
// import setup from '../../data/setup';

const state = () => ({
  all: [],
  pedidoscount: 0
})

// getters
const getters = {
  getPedidoById: (state) => (id) => {
     // console.log('id', id);
     return state.all.find(ped => ped.pednum == id)
  },
  getPedidosRetirados: (state) => {
     // console.log('id', id);
    // const pedidos = await getPedidos(pg);
    return state.all.filter(ped => ped.status == 'RETIRADO')
  }
}


// actions

const actions = {
  async getAllPedidos({ commit }, pg) {
    const pedidos = await getPedidos(pg);
    // console.log(pedidos);

    // axios.get('https://ip4prkvhtf.execute-api.us-west-2.amazonaws.com/pedidos',
    // {
    //   params:
    //   {'filter[include][]': 'cliente',
    //    'filter[order]': 'pednum DESC',
    //    'filter[where][lojaId]' : setup.lojaId,
    //    'filter[limit]': 100,
    //    'filter[skip]': 100 * pg
    //   }
    // }); // loja ze bastos
    // console.log("cliente API", pedidos.data);
    commit('setPedidos', pedidos);
  },

  async getAllNoPag({commit}){
    const pedidos = await getAllPedidosNoPagination();
    commit('setPedidos', pedidos);
  },

  async countPedidosData({commit}){
    const cp = await countPedidos();
      // console.log("Count pedidos", cp);
      commit('countPedidosCommit', cp);
  },

  async cancelaPedido({ commit }, data){
    console.log('state ID Pedido', data.id, data.motivo);

    await cancelThisPedido(data.id, data.motivo).then(()=>{
      commit('cancelPedidoState', data.id)
    });
  },

  async retiraPedido({ commit }, id){
    console.log('state ID Pedido', id)

    await retiraThisPedido(id).then(()=>{
      commit('retiraPedidoState', id)
    });
  },

  async updatePedidoAction({commit}, pedidos){
    commit('updatePedidos', pedidos)
  },

  async searchPedId({commit}, id){
    const idret = await searchPedidoIdData(id);
    commit('searchId', idret)
  },

  async searchPedCliNome({commit}, nome){
    const nomeret = await searchPedCliNomeData(nome);
    commit('searchNome', nomeret);
  }
}

const mutations = {
  setPedidos (state, pedidos) {
    state.all = pedidos
  },
  countPedidosCommit(state, pdc){
    state.pedidoscount = pdc
    console.log(pdc)
  },
  cancelPedidoState (state, id){
    var index = state.all.findIndex((pedido) => pedido._id == id);
    // state.all.splice(index, 1)
    state.all[index].status = "CANCELADO";
  },
  retiraPedidoState(state, id){
    var index = state.all.findIndex((pedido) => pedido._id == id);
    state.all[index].status = "RETIRADO";
  },
  updatePedidos(state, pedidos){
    state.all = pedidos;
  },
  searchId(state, pdid){
    state.all = pdid
  },
  searchNome(state, pdnome){
    state.all = pdnome
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
