import axios from 'axios';
// import setup from '../data/setup';


async function getClientes(skip) {
    const clientes = await axios.get(process.env.VUE_APP_API_URL + '/clientes/', { 
      params:{
        'filter[limit]': '20',
        'filter[skip]': skip,
        'filter[where][lojaId]': process.env.VUE_APP_LOJAID
      }
    });
    return clientes;
}

async function getClientesNumber(){
    const cnumber = await axios.get(process.env.VUE_APP_API_URL + '/clientes/'); 
    return cnumber;
}

export {
    getClientes,
    getClientesNumber
}