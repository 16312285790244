<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col">
          <button class="btn base-button btn-icon btn-fab btn-primary" style="width:180px;" data-bs-toggle="modal" data-bs-target="#novoProduto">Novo Produto</button>
      </div>
      <div class="col-12">
        <produtos-table />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">

        <!-- Modal -->
        <div class="modal fade" id="novoProduto" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Adicionar novo Produto</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col pb-3 ">
                    <FormKit
                    type="text"
                    label="Nome"
                    help="Nome do produto"
                    v-model="nomeproduto"
                    validation="required"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col pb-3 ">
                    <FormKit
                    type="select"
                    label="Unidade"
                    placeholder="Selecione a unidade"
                    :options="[
                      {label: 'UN', value: 'UN'},
                      {label: 'KG', value: 'KG'},
                      {label: 'CAIXA', value: 'CAIXA'}]"
                    v-model="unidadeproduto"
                    validation="required"
                    />
                  </div>
                  <div class="col pb-3 ">
                    <FormKit
                    type="text"
                    label="Preço"
                    v-model="precoproduto"
                    validation="required"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" @click="adicionaProduto()">Salvar</button>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
  </div>
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">

      <strong class="me-auto">Bootstrap</strong>
      <small>11 mins ago</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Hello, world! This is a toast message.
    </div>
  </div>
</div>
</template>

<script>
import ProdutosTable from "./components/ProdutosTable.vue";
import setup from '../data/setup.json'
// import { Toast } from 'bootstrap';
// import ProjectsTable from "./components/ProjectsTable.vue";

export default {
  name: "produtos",
  components: {
    ProdutosTable
  },
  data(){
    return{
      nomeproduto: '',
      unidadeproduto: '',
      precoproduto: '',
    }
  },
  methods: {
    adicionaProduto() {
      var produto = {
        nomeproduto: this.nomeproduto,
        unidade: this.unidadeproduto,
        precoproduto: Number(this.precoproduto),
        lojaId: setup.lojaId
      };
      this.$store.dispatch('produtos/addNewProduto', produto);
    }
  },
};

</script>

<style>
</style>
