<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <!-- <router-link :to="{ path: '/encomendanew' }"><button class="btn base-button btn-icon btn-fab btn-primary" style="width:180px;">Novo Pedido</button></router-link> -->
      <div class="col-12">
        <RetiradaForm />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-12">

      </div>
    </div>
  </div>
</template>

<script>
import RetiradaForm from "./components/RetiradaForm.vue";
import { defineComponent } from "vue";

export default defineComponent({
   name: 'Retirada',
   components: { RetiradaForm: RetiradaForm},
   setup(){
     return {
         RetiradaForm
        }
   }
});
</script>
