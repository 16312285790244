import { getProdutos ,addProduto, removeProduto, editaProduto, getProduto} from '../../service/ApiProduto';
// import setup from '../../data/setup';

const state = () => ({
  all: [],
  produtoEdita: {}
})

// getters
const getters = {}


// actions
const actions = {
  async getAllProdutos ({ commit }) {
    const produtos = await getProdutos();
    commit('setProducts', produtos.data);
    // console.log('state produtos', produtos.data)
  },
  async getProduto ({ commit }, id ) {
    const getproduto = await getProduto(id);
    // console.log('produto.data', getproduto);
    commit('getProduct', getproduto.data);
    // console.log('state produtos', produtos.data)
  },
  async addNewProduto({commit}, produto){
    var loja = process.env.VUE_APP_LOJAID;
    produto.lojaId = loja;
    await addProduto(produto).then(()=>{
      commit('addProducts', produto);
    });
  },
  async removeSetProduto({commit}, produto){
    await removeProduto(produto).then(()=>{
      commit('removeProducts', produto);
    })
  },
  async editaSetProduto({commit}, data){
    console.log('data', data.id);
    await editaProduto(data).then(()=>{
      commit('editaProducts', data);
    })
  }
}

const mutations = {
  setProducts (state, produtos) {
    state.all = produtos
  },

  addProducts (state, pro){
    console.log('pro', pro);
    state.all.push(pro);
  },

  getProduct(state, pro){
    console.log('pro', pro);
    state.produtoEdita = pro;

  },
  removeProducts(state, pro){
    // console.log(state.all);
    const objWithIndex = state.all.findIndex((obj)=> obj._id === pro);
    if (objWithIndex > -1){
      state.all.splice(objWithIndex, 1);
    }
  }, 
  editaProducts(state, pro){
    // console.log('pro',pro);
    const objWithIndex = state.all.findIndex((obj)=> obj._id === pro.id);
    // console.log(objWithIndex);
    state.all[objWithIndex].nomeproduto = pro.nome;
    state.all[objWithIndex].precoproduto = Number(pro.preco);
    state.all[objWithIndex].unidade = pro.unidade;

  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
