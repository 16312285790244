import { getClientes, getClientesNumber } from '../../service/ApiClientes'

const state = () => ({
  all: [],
  cn: 0
})

// getters
const getters = {}


// actions
const actions = {
  async getAllClientes({ commit }, skip) {
    const clientes = await getClientes(skip);

    // console.log('clientes', clientes.data);
    // console.log('skip', skip);
    commit('setClientes', clientes.data);
  },

  async getNumberClientes({ commit }) {
    const cnumber = await getClientesNumber(); 
    commit('countClients', cnumber.data.count);
  }
};

const mutations = {
  setClientes (state, clientes) {
    state.all = clientes.reverse();
  },
  countClients (state, cnumber){
    state.cn = cnumber
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
