<template>
  <div class="card" v-if="retirados">
    <div class="card-header pb-0">
      <!-- <div class="row">
        <div class="col-md-2">
          <h6>Pedidos</h6>
        </div>
        <div class="col-md-4">
          <SearchInput
            v-model="searchPedidosId"
            type="search"
            placeholder="Busca número"
            wrapperClass="search-input-wrapper"
            :class="['search-input-wrapper mt-2 w270']"
            :clearIcon="true"
            :clearOnEsc="true"
            :blurOnEsc="true"
            :selectOnFocus="true"
            :shortcutListenerEnabled="true"
            shortcutKey= "?"
          />
          <easy-spinner
          type="circular"
          size="20px"
          color="grey"
          v-if="searchCliSpin"/>
        </div>

        <div class="col-md-4">
          <SearchInput
            v-model="searchPedidosCliente"
            type="search"
            placeholder="Busca clientes"
            wrapperClass="search-input-wrapper"
            :class="['search-input-wrapper mt-2 w270']"
            :clearIcon="true"
            :clearOnEsc="true"
            :blurOnEsc="true"
            :selectOnFocus="true"
            :shortcutListenerEnabled="true"
            shortcutKey= "/"
          />
          <easy-spinner
          type="circular"
          size="20px"
          color="grey"
          v-if="searchIdSpin"/>
        </div>

      </div> -->
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-left mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Cliente</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Valor</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Produtos</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Status</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Data Pedido</th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >Data Retirada</th>
              <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ação</th> -->
            </tr>
          </thead>
          <tbody>
              <tr v-for="pedido in retirados" :key="pedido._id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div>
                      <img
                        src="../../assets/img/flag-BR.png"
                        class="avatar avatar-sm me-3"
                        alt="user1"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">Pedido: {{ pedido.pednum }} </h6>
                      <p class="text-xs text-secondary mb-0">#{{ pedido._id }}</p>
                    </div>
                  </div>
                </td>
                <td v-if="retirados.length != 0">
                  <p class="text-xs font-weight-bold mb-0">{{ pedido.cliente.nome }}</p>
                  <p class="text-xs text-secondary mb-0">{{ formatPhone(pedido.cliente.fone) }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ changeDot(pedido.total) }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success" data-bs-toggle="modal" v-bind:data-bs-target="'#prodListModal' + pedido._id">{{ pedido.produtos.length }}</span>
                </td>
                <td class="align-middle text-center text-sm">
                <!--  <span :class="pedido.status == 'ATIVO'?'badge badge-sm bg-gradient-success' : 'badge badge-sm bg-gradient-warning' " >{{ pedido.status }}</span> -->
                  <span :class="{'badge badge-sm bg-gradient-success':  pedido.status == 'ATIVO' , 'badge badge-sm bg-gradient-warning': pedido.status == 'RETIRADO' , 'badge badge-sm bg-gradient-danger': pedido.status == 'CANCELADO'}" >{{ pedido.status }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ formatDate(pedido.datapedido) }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{ formatDate(pedido.dataretirada) }}</span>
                </td>
                <!-- <td class="align-middle"> -->
                  <!-- <a
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Editar pedido"
                  >Editar</a> -->
                  <!-- <a
                    @click="cancelarPedido(pedido._id)"
                    class="text-danger font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Cancelar pedido"
                  > Cancelar</a>
                </td> -->
                <!-- Modal -->
                  <div class="modal fade" v-bind:id="'prodListModal' + pedido._id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">Produtos</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <p>{{pedido.id}}</p>
                          <table class="table table-striped">
                            <thead>
                              <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Produtos</span></th>
                              <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quant</span></th>
                              <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Valor</span></th>
                            </thead>
                            <tbody>
                              <tr v-for="(pro, index) in pedido.produtos" :key="index">
                                <td>{{ pro.produto }}</td>
                                <td>{{ pro.quant }}</td>
                                <td>{{ changeDot(pro.total) }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <p class="float-end">Total do pedido: R$ {{changeDot(pedido.total)}}</p>
                          <p class="float-start">Pagamento: {{ pedido.pagamento }}</p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                        </div>
                      </div>
                    </div>
                  </div>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
import moment from 'moment';
import {mask} from 'vue-the-mask';
import { getPedidosRetirados } from '../../service/ApiPedido'
// import SearchInput from 'vue-search-input';

export default {
  name: "RelRetiradosTable",

//  computed: mapState({
//    clientes: state => state.cliente.getAllClientes
//  }),
  data(){
    return{
      pedido_id: '',
      retirados: ''

    }
  },
  computed: {
     //  ...mapGetters({
     //    retirados: ['pedidos/getPedidosRetirados']
     // }),
    // retirados: async ()=>{
    //   var pedidos =  await getPedidos(0);
    //   console.log("pedidos", pedidos);
    //   return pedidos;
    // }

  },
  watch: {
    // searchPedidosCliente(nome){
    //
    //   console.log("altera");
    //   if (nome.length > 4){
    //     // var selected = this.pedidos.filter((item)=> (item.cliente.nome).toLowerCase() == nome.toLowerCase() );
    //     var selected = this.pedidos.filter((item)=> (item.cliente.nome).toLowerCase().includes(nome.toLowerCase()) );
    //   //  console.log(nome);
    //     this.searchIdSpin = true;
    //     if (selected){
    //         console.log('encontrado pedidos');
    //         setTimeout(()=>{
    //           this.atualizaPedidosBusca(selected);
    //           this.searchIdSpin = false;
    //         },2500)
    //     };
    //   //   console.log("selected", selected);
    //   }
    //
    //   if(nome.length == 0){
    //     this.getDataPedidos();
    //   }
    // },
  //   searchPedidosId(id){
  //     // console.log("altera");
  //     // setTimeout(()=>{
  //       if (id.length > 0){
  //         // var selected = this.pedidos.filter((item)=> (item.cliente.nome).toLowerCase() == nome.toLowerCase() );
  //         var selected = this.pedidos.filter((item)=> (item.pednum) == id );
  //         this.searchCliSpin = true;
  //         console.log("ID",id);
  //       //  if (selected.length > 1){
  //             console.log('encontrado pedidos');
  //             setTimeout(()=>{
  //               this.atualizaPedidosBusca(selected);
  //               this.searchCliSpin = false;
  //             },1500)
  //       //    }
  //         console.log(selected);
  //       }
  //
  //       if(id.length == 0){
  //         this.getDataPedidos();
  //       }
  //   }
   },

  methods:{

    formatDate(value){
      return moment(String(value)).format('DD/MM/YYYY')
    },
    formatPhone(fone){
      return "(" + String(fone).slice(0,2) + ")" + String(fone).slice(2,7) + "-" + String(fone).slice(7);
    },

    changeDot(strv){
      return String(Number(strv).toFixed(2)).replace(".",",");
    },


  },
  mounted() {
    getPedidosRetirados(0).then(rt => {
      console.log(rt);
      this.retirados = rt;
    });

  },
  directives: {mask},
  components: {
//    SearchInput,

  },
};
</script>
