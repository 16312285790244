<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          url="/dashboard-default"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'لوحة القيادة' : 'Painel'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/clientes"
          :class="getRoute() === 'clientes' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الجداول' : 'Clientes'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-favourite-28 text-danger text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/pedidos"
          :class="getRoute() === 'pedidos' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الفواتیر' : 'Pedidos'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/produtos"
          :class="getRoute() === 'produtos' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الفواتیر' : 'Produtos'"
        >
          <template v-slot:icon>
            <i class="ni ni-world text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
           <sidenav-item
             url="/retiradas"
             :class="getRoute() === 'retiradas' ? 'active' : ''"
             :navText="
               this.$store.state.isRTL ? 'الواقع الافتراضي' : 'Retiradas'"
            >
             <template v-slot:icon>
               <i class="ni ni-app text-info text-sm opacity-10"></i>
             </template>
           </sidenav-item>
         </li>
         <!-- <li class="nav-item">
           <sidenav-item
             url="/"
             :class="getRoute() === 'rtl-page' ? 'active' : ''"
             navText="Relatórios"
           >
             <template v-slot:icon>
               <i class="ni ni-collection text-danger text-sm opacity-10"></i>
             </template>
           </sidenav-item>
         </li> -->
  <!--    <li class="nav-item">
        <sidenav-item
          url="/virtual-reality"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          :navText="
            this.$store.state.isRTL ? 'الواقع الافتراضي' : 'Retiradas'
          "
        >
          <template v-slot:icon>
            <i class="ni ni-app text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/rtl-page"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="Relatórios"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
    <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
        RELATÓRIOS
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/relretirados"
          :class="getRoute() === 'relretirados' ? 'active' : ''"
          :navText="'Retirados'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/relprodutospordata"
          :class="getRoute() === 'relprodutospordata' ? 'active' : ''"
          :navText="'Produtos por data'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    <!--  <li class="nav-item">
        <sidenav-item
          url="/signup"
          :class="getRoute() === 'signup' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'اشتراك' : 'Sign Up'"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      textPrimary="Uniboi"
      textSecondary="Loja:"
    />
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <p class="text-xs text-center font-weight-bold" v-if="user">Usuário: {{ user }}</p>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Uniboi Encomendas",
      controls: "dashboardsExamples",
      isActive: "active",
      user: this.$store.state.user.email
    };
  },
  components: {
    SidenavItem,
   SidenavCard
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  },
};
</script>
