<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Novo pedido de Encomenda</h6>
    </div>
    <div class="card-body">
      <div class="card-title">
        Insira os dados do pedido
      </div>


    
      <FormKit
        type="form"
        :actions="false"
        ref="formPedido"
        id="formPedidoId"
        @submit="submitFormPed"
        >
      <div class="row">
        <div class="col pb-3 ">
          <FormKit
          type="text"
          label="ID Cartão"
          v-model="rfidcard"
          autoFocus
          validation="required"
          />
        </div>
      </div>
      <div class="row">
        <div class="col pb-3 ">
          <FormKit
          type="text"
          label="Telefone/Whatsapp"
          v-model="fone"
          v-on:focusout="searchClienteFone(fone)"
          validation="required|number|length:10"
          />
        </div>
        <div class="col pb-3 ">
          <FormKit
          type="text"
          label="CPF"
          v-model="cpf"
          v-on:focusout="searchClienteCpf(cpf)"
          validation="|number|length:11"
          />
        </div>
        <div class="col pb-3">
          <FormKit
          type="text"
          label="Nota Fiscal da venda"
          v-model="notafiscal"
          />
        </div>
      </div>
      <div class="row">
        <div class="col pb-3">
          <FormKit
            type="text"
            label="Nome do cliente"
            help="Insira o nome completo do cliente"
            v-model="nome"
            validation="required|length:10"
          />
        </div>
        <div class="col pb-3">
          <FormKit
            type="select"
            label="Forma de Pagamento"
            placeholder="Selecione o pagamento"
            help="Dinheiro, Cheque, Cartão Debito, Cartão Crédito"
            v-model="pagamento"
            :options="{
              'DINHEIRO': 'Dinheiro',
              'PIX': 'Pix',
              'CARTAO DEBITO': 'Cartão Débito',
              'CARTAO CREDITO': 'Cartão Crédito',
              'OUTROS': 'Outros'
              }"
            validation="required"
          />
        </div>
        <div class="col pb-3">
          <FormKit
            type="date"
            label="Data da Retirada"
            help="Data que o cliente vai rerirar o pedido"
            v-model="dataretirada"
            validation="required"
          />
        </div>
        <div class="col pb-3">
          <FormKit
            type="select"
            label="Faixa de Horário Retirada"
            placeholder="Selecione o horário"
            v-model="faixaHorario"
            :options="{
              '14:00hs as 15:00hs': '14:00hs as 15:00hs',
              '15:00hs as 16:00hs': '15:00hs as 16:00hs',
              '16:00hs as 17:00hs': '16:00hs as 17:00hs'
              }"
            validation="required"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-3 pb-3 ">
          <FormKit
          type="text"
          label="CEP"
          help="Digite o CEP do cliente"
          v-model="cep"
          @keyup="buscaCep()"
          />
        </div>
        <div class="col-6 col-md-3 pb-3 ">
          <FormKit
          type="text"
          label="Endereço"
          help="Digite o nome da rua ou avenida"
          v-model="logradouro"
          />
        </div>
        <div class="col-6 col-md-2 pb-3 ">
          <FormKit
          type="text"
          label="Número"
          help="Digite número do logradouro"
          v-model="numero"
          />
        </div>
        <div class="col col-md-4 pb-3 ">
          <FormKit
          type="text"
          label="Complemento"
          ref="complementoField"
          help="AP, Sala, Bloco, etc."
          v-model="complemento"
          />
        </div>
      </div>
      <div class="row">

      </div>
      <div class="row">
        <div class="col pb-3 ">
          <FormKit
          type="text"
          label="Bairro"
          v-model="bairro"
          />
        </div>
        <div class="col pb-3 ">
          <FormKit
          type="text"
          label="Cidade"
          v-model="cidade"
          />
        </div>
        <div class="col pb-3 ">
          <FormKit
          type="text"
          label="UF(Estado)"
          v-model="uf"
          />
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="produtos" tabindex="-1" aria-labelledby="labelMd" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="labelMd">Adicione os produtos na encomenda</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col pb-3 ">
                  <FormKit
                  type="select"
                  label="Produto"
                  placeholder="Selecione produto"
                  v-model="produto"
                  :options="selPro"
                  />
                </div>
                <div class="col pb-3 ">
                  <FormKit
                  type="number"
                  label="Quantidade"
                  v-model="quant"
                  />
                </div>
                <div class="row">
                  <div class="col">
                    <button
                      type="button"
                      name="button"
                      class="btn btn-secondary float-end"
                      @click="addProdutoTable()"
                      >Inserir
                    </button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col py-4">
                  <h6>Encomenda</h6>
                  <table class="table table-striped">
                    <thead>
                      <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Produtos</span></th>
                      <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Quant</span></th>
                      <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Valor</span></th>
                      <th><span class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Ação</span></th>
                    </thead>
                    <tbody>
                      <tr v-for="(linha, index) in lps" :key="linha.produto">
                        <td>{{ linha.produto }}</td>
                        <td>{{ linha.quant }}</td>
                        <td>{{ linha.total }}</td>
                        <td><span class="ni ni-fat-remove text-danger" @click="removeProduto(index)"></span></td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="float-end">Total encomenda: R$ {{ totalgeral }}</p>
                </div>

              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Confirmar</button>
            </div>
          </div>
        </div>
      </div> <!-- End MOdal -->
        <button type="reset" class="btn btn-warning"> Apagar</button>
        <button type="submit" class="btn btn-danger float-end">Finalizar e enviar</button>
    </FormKit>

    <!-- Button trigger modal -->
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#produtos">
      Adicionar Produtos
    </button>


    <!-- Modal inpressao -->
    <div class="modal fade" tabindex="-1" aria-labelledby="comprovanteMd" aria-hidden="true" ref="modalRef">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="comprovanteMd">Impressão do comprovante Retirada</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row ped-impress" id="impress">
              <img :src="imagem" style="width: 200px; display: none;">
              <h6>UNIBOI Carnes & Grill</h6>
              <p>Pedido Num: {{ retPedNum }}</p>
              <p>Nota Fiscal: {{ notafiscal }}</p>
              <p>Cliente: {{ nome }} </p>
              <p>Endereço: {{ logradouro }}, {{ numero }} - {{ complemento }}</p>
              <p>Telefone: {{ fone }}</p>
              <p>Data: </p>
              <p>Data da entrega: {{ dataretirada }} - Horários: {{ faixaHorario }}</p>
              <p>Produtos: </p>
              <ul>
                <li v-for="(linha, index) in lps" :key="index">
                    {{ linha.produto }} - Quant: {{ linha.quant }} - R$ {{ linha.total }}
                </li>
              </ul>
              <p>Total: R$ {{ totalgeral }} </p>
              <p>A presentação desse comprovante garante a retirada do seu pedido, esse comprovante</p>
              <p>é único e instránsferivel. Deve ser apresentado no dia da retirada.</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" v-print="impressObj" @click="sendClearForm()">Imprime comprovante</button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script setup>
// using vue Composition API
import axios from 'axios';
import { ref , watch , onMounted, onBeforeMount } from 'vue';
import { searchCliFone , searchRfidCard } from '../../service/ApiPedido'
// import { ArgonAlert } from '../../components/ArgonAlert';
import { useStore } from 'vuex';
import { useNotification } from "@kyvg/vue3-notification";
// import setup from '../../data/setup.json'
import { Modal } from 'bootstrap';
import QRCode from 'qrcode';
import { reset } from '@formkit/core'


    const { notify }  = useNotification();
    var nome = ref('');
    var cpf = ref('')
    var fone = ref('')
    var logradouro = ref('')
    var numero = ref('')
    var complemento = ref('')
    var bairro = ref('')
    var cidade = ref('')
    var uf = ref('')
    var cep = ref('')
    var dataretirada = ref('')
    var notafiscal = ref('')
    var faixaHorario = ref('')
    // eslint-disable-next-line no-unused-vars
    // var idproduto = ref('')
    // eslint-disable-next-line no-unused-vars
    var produto = ref('')
    // eslint-disable-next-line no-unused-vars
    var quant = ref(1)
    // eslint-disable-next-line no-unused-vars
    var totalinsert = ref('')
    // eslint-disable-next-line no-unused-vars
    var lps = ref([])
    var totalgeral = ref('')
    // eslint-disable-next-line no-unused-vars
    var selPro = ref('')
    const store = useStore()
    // eslint-disable-next-line no-unused-vars
    var clienteFound = false;
    // const formPedido = ref(null)
    var clienteId = ''
    // const formPedido = ref(null)
    // eslint-disable-next-line no-unused-vars
    var pagamento = ref('')
     // eslint-disable-next-line no-unused-vars
    var rfidcard = ref('');

    const modalRef = ref();

    var imagem = ref();
    // eslint-disable-next-line no-unused-vars
    var retPedNum = ref('');
    // eslint-disable-next-line no-unused-vars
    var impressObj = ref({
      id: "impress",
      preview: false
    });

    // eslint-disable-next-line no-unused-vars
    async function imprimirPedidoQrCode(pedId){
      QRCode.toDataURL(pedId, {width: '200'})
        .then(url => {
        //  console.log(url);
          imagem.value = url;
        })
        .catch(err => {
          console.error(err)
        })

      var myModal = new Modal(modalRef.value);
      myModal.show();
      // console.log("modal open", myModal.sho );
    }

    // eslint-disable-next-line no-unused-vars
    function sendClearForm(){
      setTimeout( ()=>{
        // eslint-disable-next-line no-undef
        reset('formPedidoId');
      }, 5000)
    }

      // eslint-disable-next-line no-unused-vars
      async function submitFormPed(){
        console.log("enviando Form");
        if (lps.value.length > 0){
          // addPedConfirm().then(()=>{
          //    nome.value = '';
          //    cpf.value = '';
          //    fone.value = '';
          //    logradouro.value = '';
          //    numero.value = '';
          //    complemento.value = '';
          //    bairro.value = '';
          //    cidade.value = '';
          //    uf.value = '';
          //    cep.value = '';
          //    dataretirada.value = '';
          //    lps.value = [];
          //    totalgeral.value = '';
          //    totalinsert.value = '';
          //    pagamento.value = '';
          //    notafiscal.value = '';
          // });
          await addPedConfirm();
        } else {
          notify({
            title: "Não realizado",
            text: "Sem produtos na encomenda!",
            position: 'top right',
            duration: 10000,
            type: 'error',
            classes: 'mt-5'
          });
        }
        // eslint-disable-next-line no-unused-vars
        //const node = formPedido.value.node;
        //node.submit();

        // submitForm('form-id')

    //    console.log(formPedido.value)
    // const submitForm = async()=>{
    async function addPedConfirm(){
        async function addPedidoInner(res){
          var today = (new Date()).toISOString();
          var retDate = String(dataretirada.value) + 'T21:36:24.256Z';
          var totalPedido = Number(Number(totalgeral.value).toFixed(2));
          axios.post(process.env.VUE_APP_API_URL + '/pedidos', {
            "total": totalPedido ,
            "pagamento": pagamento.value,
            "status": "ATIVO",
            "dataretirada": retDate,
            "datapedido": today,
            "voucher": "0",
            "cliente_id": res,
            "produtos": lps.value,
            "lojaId": process.env.VUE_APP_LOJAID,
            "notafiscal": notafiscal.value,
            "faixaHorario": faixaHorario.value,
            "rfidcard": rfidcard.value
        }).then((res)=>{
          console.log('Pedido', res.data);
          retPedNum.value = res.data.pednum;
          notify({
            title: "Pedido realizado",
            text: "O pedido foi inserido",
            position: 'top right',
            duration: 10000,
            type: 'success',
            classes: 'mt-5'
          });
          imprimirPedidoQrCode(res.data._id)
        })
      };

    if (clienteFound == false){
        await axios.post(process.env.VUE_APP_API_URL + '/clientes' , {
              'nome': nome.value,
              'cpf': cpf.value,
              'logradouro': logradouro.value,
              'numero': numero.value,
              'complemento': complemento.value,
              'bairro': bairro.value,
              'cidade': cidade.value,
              'uf': uf.value,
              'cep': cep.value,
              'fone': fone.value,
              'lojaId': process.env.VUE_APP_LOJAID
            }
          ).then(res => {
            console.log(res);
            var clid = res.data._id;
            addPedidoInner(clid);

          });
      } else {
          addPedidoInner(clienteId);
      };
    };
  };

      // eslint-disable-next-line no-unused-vars
      async function buscaCep(){
    //  const buscaCep = async()=>{
        var cepCliente = cep.value;
        console.log(cepCliente);
        if (cepCliente.length == 8) {
              await axios.get('https://brasilapi.com.br/api/cep/v2/' + cep.value )
              .then(res=>{
                console.log(res);
                logradouro.value = res.data.street;
                bairro.value = res.data.neighborhood;
                cidade.value = res.data.city;
                uf.value = res.data.state;
              })
            }
          };

      // eslint-disable-next-line no-unused-vars
      async function searchClienteFone(fone){
        // console.log('search cliente')
        await searchCliFone(fone).then((ret)=>{
          console.log('retorno', ret)
          if(ret.length > 0){
            console.log('Cliente encontrado');
            var clienteSel = ret[0];
            console.log(clienteSel);
            nome.value = clienteSel.nome;
            cpf.value = clienteSel.cpf;
            cep.value = clienteSel.cep;
            logradouro.value = clienteSel.logradouro;
            complemento.value = clienteSel.complemento;
            numero.value = clienteSel.numero;
            bairro.value = clienteSel.bairro;
            cidade.value = clienteSel.cidade;
            uf.value = clienteSel.uf;
            // eslint-disable-next-line no-unused-vars
            clienteId = clienteSel._id
            clienteFound = true;

          } else {
            console.log('Cliente não localizado');
            // eslint-disable-next-line no-unused-vars
            clienteFound = false;
          }
        })
      };
      // eslint-disable-next-line no-unused-vars
      async function searchClienteCpf(cpf){

      };
       // eslint-disable-next-line no-unused-vars
      async function searchRCard(rfidcard){
        await searchRfidCard(rfidcard).then((ret)=>{
          // console.log('retorno', ret)
          if(ret.length === 0){
            console.log('cartão nao encontrado');
         
          } else {
            console.log('cartao localizado');
            notify({
              title: "Atenção. Esse cartão já foi usado!",
              text: "Use outro cartão.",
              position: 'top center',
              duration: 5000,
              type: 'error',
              classes: 'mt-5'
          });
          reset('formPedidoId');
          }
        })
      };

      // eslint-disable-next-line no-unused-vars
      async function addProdutoTable(){
        const result = await selPro.value.filter(obj=>{
          return obj.value === produto.value
        })
        // console.log('result', result[0])


      var linha = {
          id: produto.value,
          produto: result[0].label,
          quant: quant.value,
          total: (Number(result[0].precoproduto) * Number(quant.value)).toFixed(2),
        };


    /*    var linha = {
          produto: result.produtonome,
          quant: quant.value,
          preco: result.precoproduto,
          total: (Number(result.precoproduto) * Number(quant.value))
        }
    */
        /*if (linha.index > 0 ){
          linha.index ++
        } else {
          linha.index == 0
        } */
        lps.value.push(linha);
        totalgeral.value = (Number(linha.total) + Number(totalgeral.value)).toFixed(2);
        produto.value = '';
        quant.value = 1;
        totalinsert.value = '';
        // totalgeral.value = linha.reduce((tt, obj)=> obj.total + tt,0)
        // totalgeral.valeu = Number(linha.total) +
        // console.log(linha);
      }

      // eslint-disable-next-line no-unused-vars
      function removeProduto(index){
        totalgeral.value = (Number(totalgeral.value) - Number(lps.value[index].total)).toFixed(2);
        lps.value.splice(index,1);
        // console.log(totalgeral.value);
      }

      //function getProdutosSelect(){
        // this.$store.dispatch('produtos/getAllProdutos')
      //  return store.state.produtos
      // }

      async function getAllProdutosFromStore(){
        await store.dispatch('produtos/getAllProdutos');
        var arr = [];
        arr = store.state.produtos.all;
        arr.map((k)=> {
          k.value = k._id;
          k.label = k.nomeproduto;
          delete k._id;
          delete k.nomeproduto;
        });
        // console.log('spss' , arr);
        selPro.value = arr;
      }

      onBeforeMount(()=>{
        getAllProdutosFromStore();
      })

      onMounted(()=>{
        // getAllProdutosFromStore();
        // rfidcard.value?.focus();

      })

      // const myPrint = {
      //   beforeMount: (el) => {
      //
      //   }
      // }

      watch(rfidcard, async(newRefid)=>{
        if(rfidcard.value.length >= 10){
          searchRCard(newRefid);
        }
      } )
</script>


<style scoped>
  #impress{
    font-family: Arial;
    color: black;
  }
  .ped-impress p{
    font-size: 10px;
    margin: 0;
  }
  .ped-impress ul{
    list-style-type: none;
  }
  .ped-impress li{
    font-size: 10px;
    margin: 0;
  }
</style>
